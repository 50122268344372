import { useQueryClient } from 'react-query';
import { IAllDataCMS, ICMSConfig, ICMSConfigPage } from './cms.models';
import { PAGES } from '@vfit/shared/data-access';
/**
 *  API CLASS
 */
export const API = {
  // region Services

  SILENT_LOGIN: 'authentication/external-user/v1/silentLogin',
  ACCOUNT_SELECTION: 'authentication/external-user/v1/accountSelection',
  CARE_V1: 'care/v1',
  OFFERING_SERVICEABILITY: 'commerce/v1/offeringServiceability',
  ADDRESS: 'care/v1/address',
  VALIDATE_ADDRESS: 'care/v1/validateAddress',
  REPLACE_BILLING_ADDRESS: 'billingCare/v1/billingAccount',
  SHOPPING_CART: 'commerce/v1/shoppingCart',
  CHECK_CART_IN_COOKIE: 'checkCartInCookie',
  RETRIEVE_CART_IN_COOKIE: 'retrieveCartFromCookie',
  CART_ITEM: 'cartItem',
  CUSTOMER: 'care/v1/customer',
  FIND_CALLER: 'care/v1/findCaller',
  COUNTRY: 'care/v1/country',
  FISCAL_CODE: 'care/v1/fiscalCodeBirthPlace',
  COEXISTENCE_USER: 'commerce/v1/coexistenceuser',
  CUSTOMER_REFERENCE_DATA: 'care/v1/customerReferenceData',
  ASSOCIATE_CUSTOMER: '/associateCustomer',
  LINK_CONTACT_CUSTOMER: 'care/v1/linkContactAndCustomer',
  INDIVIDUAL: 'care/v1/individual',
  ORDER: 'commerce/v1/order',
  ORDER_V2: 'commerce/v2/order',
  PAYMENT_MANAGEMENT_ORDER: 'commerce/v1/paymentManagement/order',
  UPDATE_PRODUCT_CHARACTERISTIC: 'updateProductCharacteristic',
  MODIFY_MOBILE_SIM_DEVICE: 'modifyMobileSimDevice',
  UPDATE_PRODUCT_CHARACTERISTIC_LIGHT: 'updateProductCharacteristicLight',
  CHECKOUT: 'checkout',
  DELIVERY_DETAILS: '/deliveryDetails',
  RESERVE: 'commerce/v1/phoneNumber/reserve',
  RELEASE_RESOURCE: 'commerce/v1/phoneNumber/releaseResource',
  BILLING_ACCOUNT: 'billingAccount',
  NOTIFY: 'notify',
  BILLING_DELIVERY_DETAILS: 'billDeliveryDetails',
  PAYMENT: 'payment',
  PAYMENT_METHOD: 'paymentMethod',
  PAYMEAN: 'payMean',
  ORDER_TRACKING: 'orderTrackingDetails',
  AUTHORIZE: 'authorize',
  AUTHORIZATION: 'authorization',
  PAYMENT_DISTRIBUTION: 'paymentDistribution',
  RESERVE_EQUIPMENT: 'reserveEquipment',
  VALIDATE_IBANS: 'validateIbans',
  LIGHT_CREDIT_VETTING: 'lightCreditVetting',
  SUBMIT: 'submit',
  SUBMIT_WITH_PAYMENT: 'submitWithPayment',
  SEND_EMAIL_NOTIFICATION: 'sendEmailNotification',
  MULTI_PLAYING_OFFERING_CONFIGURATION: 'commerce/v1/multiPlayOfferingConfiguration',
  VALIDATE_DUPLICATE_ORDER: 'commerce/v1/validateDuplicateOrder',
  CUSTOMER_REGISTRATION: 'tmfdigitalidentity/tmf-api/digitalIdentityManagement/v4/digitalIdentity',
  GENERATE: 'care/v1/otp/generate',
  VERIFY: 'care/v1/otp/verify',
  SEARCH_SUBSCRIBER_BY_RESOURCE: 'billingcare/v1/searchSubscriberByResource',
  CHECK_IN_PORT: 'commerce/v1/checkPortInFeasibilityII',
  GET_PORTABILITY_INFO: 'commerce/v1/portininfo',
  MODIFY_PHONE_NUMBER_PRODUCT: 'modifyPhoneNumberProduct',
  PRIVACY_PROFILE: 'privacyProfile',
  RETRIEVE_MULTILINES: 'privacyProfile/retrieveMultiLines',
  SEARCH_PICKUP_LOCATIONS: 'searchPickUpLocations',
  LOCATE_STORE: 'locateStores',
  CAMPAIGNS: 'commerce/v1/campaigns',
  CAMPAIGN: 'commerce/v1/campaign',
  ACCEPT: 'accept',
  UNFREEZE: 'unfreeze',
  CHECK_REFUND: 'landing/checkrefund',
  ACTIVABLE_PRODUCTS: 'journeycatalog/v2/activableproducts',
  DECRYPT_DATA: 'eshop/decryptdata',
  DXL_CUSTOMER: 'CustomerManagement/v2/customer',
  VIDEO_RECOGNITION: 'eshop/videoRecognition',
  VOLA_CLIENT: 'eshop/volaclient',
  CREATE_SLOT_TIME: 'eshop/createSlotTime',
  MOVE_APPOINTMENT: 'eshop/moveAppointment',
  SIM_TYPE: 'commerce/v1/simInformation',
  USER: 'authentication/v1/user',
  LOGIN_ENRICHMENT: 'authorization/v1/loginByNetworkEnrichment',
  LOGIN_ENRICHMENT_V2: 'authorization/v2/loginByNetworkEnrichment',
  ATTACHMENT_LIST: 'care/v1/attachment/list',
  ATTACHMENT_DOWNLOAD_DOCUMENT: 'care/v1/attachment/download',
  RETRIEVE_DYNAMIC_ATTRIBUTES: '/retrieveDynamicAttributes',
  DECRYPT_DATA_RESHAPING: 'common/v1/decrypt',
  CUSTOMER_APROVAL_SELECTION: 'confirmCustomerAprovalSelection',
  PARTNER_OFFER_VALIDATE: 'commerce/v1/partnerOffer/fiscal/validate',
  DXL_IDENTIFY: 'customeridentification/v1/identify',
  DXL_LOGIN_BY_USER_AND_PASS: 'authorization/v1/loginByUsernameAndPassword',
  DXL_LOGIN_BY_USER_AND_PASS_V2: 'authorization/v2/loginByUsernameAndPassword',
  DXL_ACTIVE_PRODUCT_LIST: 'catalog/v1/retrieveCustomerProducts',
  DXL_ACTIVABLE_PRODUCTS: 'catalog/v1/retrieveActivableProducts',
  DXL_GET_ITEMS: 'items/v1/retrieveItems',
  DXL_ACTIVATE_PRODUCTS: 'catalog/v1/activateProducts',
  DXL_REGISTER_DEVICE: 'device/v1/registerDevice',
  DXL_USER_APP_V1: 'authorization/v1/user',
  DXL_USER_ENRICHED_CHALLENGE_APP_V1: 'authorization/v1/retrieveNetworkEnrichmentChallenge',
  DXL_VALIDATE_MFA_ENRICHMENT_CHALLENGE_AND_LOGIN_V1:
    'authorization/v1/validateNetworkEnrichmentChallenge',
  DXL_VALIDATE_MFA_CHALLENGE_AND_LOGIN_V2: 'authorization/v2/validateMfaChallengeAndLogin',
  VOUCHER: 'voucher',
  VALIDATE_VOUCHER: 'validateVoucher',
  DXL_CREATE_FACTOR: 'authenticator/v2/user',
  DXL_VALIDATE: 'authenticator/v2',
  DXL_RETRIEVE_VOUCHER: 'eshop/retrieveVoucher',

  // endregion

  // region HUB APP
  CMS_GET_HUB_APP: 'consumer/snodo-app/snodo-app-main',
  CMS_GET_HUB_APP_ACTIVATION: 'consumer/snodo-app/snodo-app-activation',
  // endregion

  // START - TPO PAGES
  CMS_GET_TPO_PAGE: 'consumer/tpo/tpo-page',
  CMS_GET_TPO_EDITORIAL_CONFIG: 'consumer/tpo/tpo-editorial-config',
  CMS_GET_TPO_PAGE_NAVIGATION: 'consumer/tpo/tpo-page-navigation',

  // START - ENRG PAGES
  CMS_GET_ENRG_PAGE: 'consumer/enrg/enrg-page',
  CMS_GET_ENRG_PAGE_NAVIGATION: 'consumer/enrg/enrg-page-navigation',

  // region FlowMobileLine

  CMS_GET_DELIVERY_INFORMATION_MOBILE: 'consumer/eshop/mobile-line/common/delivery-information',
  CMS_GET_PAYMENTS_MOBILE: 'consumer/eshop/mobile-line/common/payments-information',
  CMS_GET_PERSONAL_DATA_MOBILE: 'consumer/eshop/mobile-line/common/personal-data',
  CMS_GET_PORTABILITY_MOBILE: 'consumer/eshop/mobile-line/common/portability',
  CMS_GET_TERMS_AND_CONDITIONS_MOBILE: 'consumer/eshop/mobile-line/common/terms-and-conditions',
  CMS_GET_SUPPORT_MODULE_MOBILE: 'consumer/eshop/mobile-line/common/support-module',
  CMS_GET_THANK_YOU_PAGE_MOBILE: 'consumer/eshop/mobile-line/common/thank-you-page',
  CMS_GET_GENERIC_ERROR_MOBILE: 'consumer/eshop/mobile-line/common/generic-error',
  CMS_GET_CART_ERRORS_MOBILE: 'consumer/eshop/mobile-line/common/cart-errors',
  CMS_GET_ORDER_ERROR_MOBILE: 'consumer/eshop/mobile-line/common/order-errors',
  CMS_GET_CUSTOMER_ERROR_MOBILE: 'consumer/eshop/mobile-line/common/customer-errors',
  CMS_GET_EDIT_ADDRESS_MOBILE: 'consumer/eshop/mobile-line/common/edit-address',
  CMS_GET_MAP_MOBILE: 'consumer/eshop/mobile-line/common/store-map',
  CMS_GET_EDIT_ADDRESS_STEPPER_MOBILE: 'consumer/eshop/mobile-line/common/edit-address-store',
  CMS_GET_WINBACK_DATA: 'consumer/eshop/mobile-line/common/winback',
  CMS_GET_WINBACK_LEGACY_DATA: 'consumer/eshop/mobile-line/common/winback-legacy',
  CMS_GET_WINBACK_CONFIG: 'consumer/eshop/mobile-line/campaigns-configurations/all',
  CMS_GET_FAMILY_PLUS_PAYMENT: 'consumer/eshop/mobile-line/common/familyPlus/payments',
  CMS_GET_MOBILE_VOUCHERS: 'consumer/eshop/mobile-line/common/vouchers',
  CMS_GET_VOUCHER_INSERTION_MANAGMENT_CONF: 'consumer/eshop/fixed-line/common/voucher-apply',
  CMS_GET_ADDONS_MOBILE: 'consumer/eshop/mobile-line/addons/addons-list/all',
  CMS_GET_ADDONS_COMMON_MOBILE: 'consumer/eshop/mobile-line/addons/addons-common',
  CMS_GET_DETAILS_PAGE_MOBILE: 'consumer/eshop/mobile-line/common/details-page',
  CMS_GET_BEST_OFFER_VF_FW: 'consumer/eshop/fixed-line/common/best-offer-vf-fw',

  // endregion

  // region FlowFixedLine

  CMS_GET_COVERAGE_TOOL_CONFIG: 'consumer/eshop/fixed-line/common/coverage-tool',
  CMS_GET_PERSONAL_DATA: 'consumer/eshop/fixed-line/common/personal-data',
  CMS_GET_BILLING_INFORMATION: 'consumer/eshop/fixed-line/common/billing-information',
  CMS_GET_SUPPORT_MODULE: 'consumer/eshop/fixed-line/common/support-module',
  CMS_GET_TERMS_AND_CONDITIONS: 'consumer/eshop/fixed-line/common/terms-and-conditions',
  CMS_GET_PORTABILITY: 'consumer/eshop/fixed-line/common/portability',
  CMS_GET_DELIVERY_INFORMATION: 'consumer/eshop/fixed-line/common/delivery-information',
  CMS_GET_PAYMENTS: 'consumer/eshop/fixed-line/common/payments-information',
  CMS_GET_THANK_YOU_PAGE: 'consumer/eshop/fixed-line/common/thank-you-page',
  CMS_GET_MAP: 'consumer/eshop/fixed-line/common/store-map',
  CMS_GET_TECNOLOGIES: 'consumer/eshop/fixed-line/common/technologies/all',
  CMS_GET_DEVICES: 'consumer/eshop/fixed-line/common/devices/all',
  CMS_GET_GENERIC_ERROR: 'consumer/eshop/fixed-line/common/generic-error',
  CMS_GET_ORDER_ERROR: 'consumer/eshop/fixed-line/common/order-errors',
  CMS_GET_CUSTOMER_ERROR_FIXED: 'consumer/eshop/fixed-line/common/customer-errors',
  CMS_GET_EDIT_ADDRESS: 'consumer/eshop/fixed-line/common/edit-address',
  CMS_CONTRACT_PATH: 'content/dam/myvf/mva10/cce-pdf',
  CMS_GET_EDIT_ADDRESS_STEPPER: 'consumer/eshop/fixed-line/common/edit-address-store',
  CMS_GET_DROPPED_CARD: 'consumer/eshop/fixed-line/common/dropped-cart',
  CMS_GET_DROPPED_CART_HOMEPAGE: 'consumer/eshop/fixed-line/common/dropped-cart-homepage',
  CMS_GET_CLICK_TO_CALL_CONF: 'consumer/eshop/fixed-line/common/click-to-call',
  CMS_GET_CLICK_TO_CALL_APP_CONF: 'consumer/eshop/fixed-line/common/click-to-call-app',
  CMS_GET_FIXED_VOUCHERS: 'consumer/eshop/fixed-line/common/vouchers',
  CMS_GET_VOUCHER_DISCOUNT: 'consumer/eshop/fixed-line/common/voucherdiscount',
  CMS_GET_ACTIVATION_LANDING_PRODUCT: 'consumer/eshop/fixed-line/common/activation-landing-product',
  CMS_GET_ADDONS: 'consumer/eshop/fixed-line/addons/addons-list/all',
  CMS_GET_ADDONS_COMMON: 'consumer/eshop/fixed-line/addons/addons-common',
  CMS_GET_DETAILS_PAGE: 'consumer/eshop/fixed-line/common/details-page',
  CMS_GET_STEPPER_COMMON_LABELS_FIXED: 'consumer/eshop/fixed-line/common/i-buy-common-labels',
  CMS_GET_STEPPER_COMMON_LABELS_MOBILE: 'consumer/eshop/mobile-line/common/i-buy-common-labels',

  // endregion

  // region consumer Globals

  CMS_GET_BRIGHT_SKY_CONF: 'consumer/global/bright-sky',
  CMS_GET_GLOBAL_CONFIG: 'consumer/global/global-config',
  CMS_GET_STICKY_BANNER: 'consumer/global/header-sticky-banner',
  CMS_GET_FLOATING_BANNER: 'consumer/global/floating-banner',
  CMS_GET_GLOBAL_PROMO: 'consumer/global/global-promo',
  CMS_GET_GLOBAL_PROMO_IADD: 'consumer/global/global-promo-iadd',
  CMS_GET_DIGITAL_BUNDLE_OPERATORS: 'consumer/global/digital-bundle-operators',

  // endregion

  // region LandingPage

  CMS_GET_FATTURAZIONE2830: 'landing/fatturazione-2830',
  CMS_GET_VIDEO_RECOGNITION: 'landing/video-recognition',
  CMS_GET_RESHAPING_ALL: 'landing/reshaping-offer/all',
  CMS_GET_RESHAPING_PRODUCT_CARDS: 'landing/reshaping-offer/product-cards',
  CMS_GET_ONBOARDING_VIEWS_COMMON: 'landing/onboarding/views/common',
  CMS_GET_ONBOARDING_STEPPERCARDS_COMMON: 'landing/onboarding/dashboard/steppercards/common',
  CMS_GET_ONBOARDING_MODULES_COMMON: 'landing/onboarding/dashboard/modules/common',
  CMS_GET_ONBOARDING_DASHBOARD_COMMON: 'landing/onboarding/dashboard/common',
  CMS_GET_ONBOARDING_WIZARD_TVBOX: 'landing/onboarding/wizards/tv-box',
  CMS_GET_ONBOARDING_WIZARD_POWERSTATION: 'landing/onboarding/wizards/power-station',
  CMS_GET_ONBOARDING_WIZARD_POWERWIFI: 'landing/onboarding/wizards/power-wifi',
  CMS_GET_ONBOARDING_WIZARD_WIFIEXTENDER: 'landing/onboarding/wizards/wifi-extender',
  CMS_GET_ONBOARDING_WIZARD_INVOICE_HELP: 'landing/onboarding/wizards/invoice-help',
  CMS_GET_ONBOARDING_WIZARD_ALTERNATIVE_MODEM_FTTH:
    'landing/onboarding/wizards/alternative-modem-ftth',
  CMS_GET_ONBOARDING_WIZARD_ALTERNATIVE_MODEM_ADSL:
    'landing/onboarding/wizards/alternative-modem-adsl',
  CMS_GET_ONBOARDING_WIZARD_ALTERNATIVE_MODEM_VDSL:
    'landing/onboarding/wizards/alternative-modem-vdsl',
  CMS_GET_ONBOARDING_DEVICES: 'landing/onboarding/devices/all',
  CMS_GET_ONBOARDING_PRODUCT_CARDS: 'landing/onboarding/product-cards',
  CMS_GET_ONBOARDING_OFFER_CARDS: 'landing/onboarding/offer-cards',
  CMS_GET_ONBOARDING_YOUR_OFFER_CARDS: 'landing/onboarding/your-offer-cards/all',
  CMS_GET_ONBOARDING_STEPPERCARDS_TECH: 'landing/onboarding/dashboard/steppercards/all',
  CMS_GET_ONBOARDING_ERRORS: 'landing/onboarding/errors',
  CMS_GET_ONBOARDING_VOUCHERS: 'landing/onboarding/vouchers/all',
  CMS_GET_TECH_APPOINTMENT: 'landing/techappointment',
  CMS_GET_TECH_APPOINTMENT_FAQ: 'landing/techappointment/faq/all',
  CMS_GET_ONE_CLICK: 'landing/one-click',
  CMS_GET_CONTRACT_SUMMARY: 'landing/reshaping-offer/sintesi-contrattuali/id-prodotti',
  CMS_GET_MULTI_FACTOR_AUTH: 'landing/multi-factor-auth',
  // endregion

  // region PagesAll

  CMS_GET_PAGES_CONSUMER: 'consumer/pages/all',
  CMS_GET_PAGES_BUSINESS: 'business/pages/all',
  CMS_GET_PAGES_ESHOP_BUSINESS: 'eshop-business/pages',
  CMS_GET_PAGES_GRANDIAZIENDE: 'grandi-aziende/pages/all',
  CMS_GET_PAGE_BUSINESS_NAVIGATION: 'landing/homepage-business/page-navigation',
  CMS_GET_PAGE_GRANDIAZIENDE_NAVIGATION: 'landing/homepage-grandiaziende/page-navigation',
  CMS_GET_PAGE_NAVIGATION: 'landing/homepage/page-navigation',
  CMS_GET_CUSTOM_PAGE_NAVIGATION: 'consumer/global/custom-page-navigation',
  CMS_GET_ALL_LEADS: 'leads/all',

  // endregion

  // region DEVICES

  CMS_GET_DEVICE_ALL: 'consumer/eshop/devices/all',
  CMS_GET_DEVICE_CHARACTERISTICS: 'consumer/global/device-characteristics',

  // endregion

  DXL_ANONYMOUS_LOGIN: 'JsLogin/v2/anonymousLogin',
  DXL_GET_ECOMMERCE_CATALOG: 'ecommercemanagement/v1/catalog',
  DXL_GET_GEOGRAPHIC_ADDRESS_MANAGEMENT:
    'geographicaddressmanagement/v1/geographicaddressvalidation',
  DXL_GET_LANDING_PAGE: 'configuratormanagement/v1/landingpage',
  DXL_GET_PROFILE: 'WebbusLogin/v1/profile',
  DXL_COOKIE_LOGIN: 'JsLogin/v2/cookieLogin',
  DXL_CB_SSO_LOGIN: 'loginviasso/v1/login',
  LEGACY_LOGIN: 'area-utente/fai-da-te/Common/PaginaUnicadiLogin.html',
  IS_LOGGED: 'ssoapi/auth/islogged',
  KEEP_ALIVE: 'area-utente/KeepAlive',
  DXL_APP_GUEST: 'v3/auth/guest',
  DXL_APP_SECURE_IDENTIFY: 'v3/auth/secureIdentify',
  DXL_SHOPPING_CART: 'ShoppingCart/v2/shoppingCart',

  CMS_GET_COMMON_ERROR: 'eshop-business/common/errors',
  CMS_GET_CAPACITY_AND_COLOR: '/devices-info.json',

  /* START - CMS BFL (business fixed line) services */
  CMS_BFL_GET_PAGE: 'eshop-business/fixed-line',
  CMS_BFL_GET_PRODUCTS: 'eshop-business/fixed-line/products/all',
  CMS_BFL_GET_COVERAGE_TOOLS: 'eshop-business/fixed-line/common/coverage-tool',
  CMS_BFL_GET_PERSONAL_DATA: 'eshop-business/fixed-line/common/personal-data',
  CMS_BFL_GET_COMMON_PLACEHOLDER: 'eshop-business/fixed-line/common/placeholders',
  CMS_BFL_GET_COMMON_WHATSAPP: 'eshop-business/fixed-line/common/whatsapp',
  CMS_BFL_GET_TECNOLOGIES: 'eshop-business/fixed-line/common/technologies/all',
  CMS_BFL_GET_DEVICES: 'eshop-business/fixed-line/common/devices/all',
  CMS_BFL_GET_SHOPPING_CART: 'eshop-business/fixed-line/common/shopping-cart',
  CMS_BFL_GET_DETAIL_PRODUCTS: 'eshop-business/fixed-line/detail-products/all',
  CMS_BFL_GET_ADDONS: 'eshop-business/fixed-line/common/addons/all',
  /* END   - CMS BFL (business fixed line) services */

  /* START - CMS BM (business mobile) services */
  CMS_BML_GET_COMMON_PLACEHOLDER: '/eshop-business/mobile-line/common/placeholders',
  /* END - CMS BM (business mobile) services */

  /* START - CMS BM (business mobile-voce) services */
  CMS_BVL_GET_PAGE: 'eshop-business/mobile-line',
  CMS_BVL_GET_PRODUCTS: 'eshop-business/mobile-line/products/all',
  CMS_BVL_GET_ADDONS: 'eshop-business/mobile-line/common/addons/all',
  CMS_BVL_GET_DETAIL_PRODUCTS: 'eshop-business/mobile-line/detail-products/all',
  CMS_BML_GET_COMMON_WHATSAPP: 'eshop-business/mobile-line/common/whatsapp',
  /* END - CMS BM (business mobile-voce) services */

  /* START - CMS BM (business mobile-dati) services */
  CMS_BDL_GET_PAGE: 'eshop-business/dati-line',
  CMS_BDL_GET_PRODUCTS: 'eshop-business/dati-line/products/all',
  CMS_BDL_GET_ADDONS: 'eshop-business/dati-line/common/addons/all',
  CMS_BDL_GET_DETAIL_PRODUCTS: 'eshop-business/dati-line/detail-products/all',
  CMS_BDL_GET_WHATSAPP: 'eshop-business/dati-line/common/whatsapp',
  /* END - CMS BM (business mobile-dati) services */

  /* START - CMS DS (business device smartphone) services */
  CMS_DS_GET_PAGE: 'eshop-business/smartphone',
  CMS_DS_GET_DEVICES: 'eshop-business/smartphone/products/all',
  CMS_DS_GET_COMMON_PLACEHOLDER: 'eshop-business/smartphone/common/placeholders',
  CMS_DS_GET_DETAIL_PRODUCTS: 'eshop-business/smartphone/detail-products/all',
  CMS_DS_GET_COMMON_WHATSAPP: 'eshop-business/smartphone/common/whatsapp',
  /* END - CMS DS (business device smartphone) services */

  /* START - CMS DT (business device tablet) services */
  CMS_DT_GET_PAGE: 'eshop-business/tablet',
  CMS_DT_GET_DEVICES: 'eshop-business/tablet/products/all',
  CMS_DT_GET_COMMON_PLACEHOLDER: 'eshop-business/tablet/common/placeholders',
  CMS_DT_GET_DETAIL_PRODUCTS: 'eshop-business/tablet/detail-products/all',
  CMS_DT_GET_COMMON_WHATSAPP: 'eshop-business/tablet/common/whatsapp',
  /* END - CMS DT (business tablet) services */

  /* START - CMS DSIOT (business digital solution iot) services */
  CMS_DSIOT_GET_COMMON_WHATSAPP: 'eshop-business/ds-line/common/whatsapp',
  CMS_DSIOT_GET_COMMON_PLACEHOLDER: 'eshop-business/ds-line/common/placeholders',
  /* END - CMS DSIOT (business digital solution iot) servicies */

  /* START - CMS BTO  services */
  CMS_BTO_GET_OFFERS_FILTER: 'external-plans.offer-mappings',
  CMS_BTO_GET_OFFERS_FILTER_NEW: 'external-plans.offer-mappings.json',
  CMS_BTO_GET_PLANS_OPERATOR: 'external-plans.operator-country',

  /* END - CMS BTO  services */

  // region AWS Services
  AWS_AVAILABLE_TIME_SLOTS: 'appointmentmanagement/v1/availabletimeslots',
  AWS_SCHEDULE: 'appointmentmanagement/v1/schedule',
  AWS_SEND_OTP: 'esimadapter/v1/sendotp',
  AWS_VERIFY_RETRIEVE_DATA: 'esimadapter/v1/verifyotptoken',
  AWS_SEND_CONFIRMATION_OTP: 'serialconfirmationadapter/v1/sendotp',
  AWS_VERIFY_CONFIRMATION_OTP_TOKEN: 'serialconfirmationadapter/v1/verifyotptoken',
  AWS_END_SERIAL_CONFIRMATION: 'serialconfirmationadapter/v1/endserialconfirmation',
  // end region

  // START - tariffe estero CBU
  CMS_ABROAD_GET_HOME: 'consumer/tariffe-estero/homepage',
  CMS_ABROAD_GET_HOME_V2: 'consumer/tariffe-estero/homepage-v2',
  CMS_ABROAD_GET_ROAMING_AEREO: 'consumer/tariffe-estero/roaming-aereo',
  CMS_ABROAD_GET_ROAMING_AEREO_V2: 'consumer/tariffe-estero/roaming-aereo-v2',
  CMS_ABROAD_GET_PAESE_UE: 'consumer/tariffe-estero/paese-europa',
  CMS_ABROAD_GET_PAESE_UE_V2: 'consumer/tariffe-estero/paese-europa-v2',
  CMS_ABROAD_GET_DETTAGLIO_PAESE_UE: 'consumer/tariffe-estero/dettaglio-paese-europa',
  CMS_ABROAD_GET_DETTAGLIO_PAESE_UE_V2: 'consumer/tariffe-estero/dettaglio-paese-europa-v2',
  CMS_ABROAD_GET_DETTAGLIO_PAESE_MONDO: 'consumer/tariffe-estero/dettaglio-paese-mondo',
  CMS_ABROAD_GET_DETTAGLIO_PAESE_MONDO_V2: 'consumer/tariffe-estero/dettaglio-paese-mondo-v2',
  CMS_ABROAD_GET_DETTAGLIO_PAESE_ENRICHED_V2: 'consumer/tariffe-estero/dettaglio-paese-enriched-v2',
  CMS_ABROAD_GET_DETTAGLIO_TEST_EDITINGS_V2: 'consumer/tariffe-estero/dettaglio-test-editings-v2',
  CMS_ABROAD_GET_EDITORIAL_CONFIG: 'consumer/global/abroad-editorial-configs/all',
  CMS_CTO_GET_PLANS_OPERATOR_CBU: 'external-plans.operator-country-cbu',
  CMS_GET_ALL_DEACTIVATION_MODAL: 'deactivation-modal/all',
  CMS_GET_CTC_AUTO_DESC: 'consumer/eshop/fixed-line/common/ctc-auto-desc',
  CMS_GET_RECURRENT_ORDER_ERROR_MOBILE: 'consumer/eshop/mobile-line/common/order-recurrent-errors',
  CMS_GET_INSTANT_ORDER_ERROR_MOBILE: 'consumer/eshop/mobile-line/common/order-instant-errors',

  // END - tariffe estero CBU

  // START - Get all nations VBI
  CMS_GET_NATIONS_VBI: 'nations-vbi',
  // END - Get all nations VBI

  // START - Get all nations VBI
  CMS_GET_NATIONS_CBU: 'nations-cbu',
  // END - Get all nations VBI

  // START - CMS CTO Plans
  CMS_CTO_GET_WORLD_OFFERS: 'external-plans.offers-world',
  CMS_CTO_GET_OFFERS_FILTER: 'external-plans.offers-world-mapping',
  // END - CMS CTO Plans

  // START - CMS page not found
  CMS_PAGE_NOT_FOUND: 'eshop-business/404',
  // END - CMS page not found

  /* aradia */
  GET_REDIRECT_LINK: 'api/v1/GetRedirectLink',
};

// TODO: REMOVE ALL APIS IN CMS CONFIG
export const CMS_CONFIG: ICMSConfig = {
  [PAGES.CONSUMER]: {
    apis: [
      {
        key: 'getMobileLinePayments',
        api: API.CMS_GET_PAYMENTS_MOBILE,
      },
      {
        key: 'getDeliveryInformationMobile',
        api: API.CMS_GET_DELIVERY_INFORMATION_MOBILE,
      },
      {
        key: 'getPageNavigation',
        api: API.CMS_GET_PAGE_NAVIGATION,
      },
      {
        key: 'getCustomPageNavigation',
        api: API.CMS_GET_CUSTOM_PAGE_NAVIGATION,
      },
      {
        key: 'getCoverageToolConfig',
        api: API.CMS_GET_COVERAGE_TOOL_CONFIG,
      },
      {
        key: 'getPortabilityCard',
        api: API.CMS_GET_PORTABILITY,
      },
      {
        key: 'getPortabilityCardMobile',
        api: API.CMS_GET_PORTABILITY_MOBILE,
      },
      {
        key: 'getWinbackNumberCard',
        api: API.CMS_GET_WINBACK_DATA,
      },
      {
        key: 'getWinbackLegacyNumberCard',
        api: API.CMS_GET_WINBACK_LEGACY_DATA,
      },
      {
        key: 'getWinbackConfiguration',
        api: API.CMS_GET_WINBACK_CONFIG,
      },
      {
        key: 'getFamilyPlusPayment',
        api: API.CMS_GET_FAMILY_PLUS_PAYMENT,
      },
      {
        key: 'getPersonalDataCard',
        api: API.CMS_GET_PERSONAL_DATA,
      },
      {
        key: 'getPersonalDataCardMobile',
        api: API.CMS_GET_PERSONAL_DATA_MOBILE,
      },
      {
        key: 'getBillingInformation',
        api: API.CMS_GET_BILLING_INFORMATION,
      },
      {
        key: 'getSupportModule',
        api: API.CMS_GET_SUPPORT_MODULE,
      },
      {
        key: 'getTermsAndConditionsCard',
        api: API.CMS_GET_TERMS_AND_CONDITIONS,
      },
      {
        key: 'getTermsAndConditionsCardMobile',
        api: API.CMS_GET_TERMS_AND_CONDITIONS_MOBILE,
      },
      {
        key: 'getDeliveryInformation',
        api: API.CMS_GET_DELIVERY_INFORMATION,
      },
      {
        key: 'getPayments',
        api: API.CMS_GET_PAYMENTS,
      },
      {
        key: 'getThankYouPage',
        api: API.CMS_GET_THANK_YOU_PAGE,
      },
      {
        key: 'getThankYouMobile',
        api: API.CMS_GET_THANK_YOU_PAGE_MOBILE,
      },
      {
        key: 'getMap',
        api: API.CMS_GET_MAP,
      },
      {
        key: 'getCmsTecnologies',
        api: API.CMS_GET_TECNOLOGIES,
      },
      {
        key: 'getCmsAddons',
        api: API.CMS_GET_ADDONS,
      },
      {
        key: 'getCmsAddonsMobile',
        api: API.CMS_GET_ADDONS_MOBILE,
      },
      {
        key: 'getAddonsCommon',
        api: API.CMS_GET_ADDONS_COMMON,
      },
      {
        key: 'getAddonsCommonMobile',
        api: API.CMS_GET_ADDONS_COMMON_MOBILE,
      },
      {
        key: 'getCmsDevices',
        api: API.CMS_GET_DEVICES,
      },
      {
        key: 'getGenericError',
        api: API.CMS_GET_GENERIC_ERROR,
      },
      {
        key: 'getPages',
        api: API.CMS_GET_PAGES_CONSUMER,
      },
      {
        key: 'getOrderError',
        api: API.CMS_GET_ORDER_ERROR,
      },
      {
        key: 'getSupportMobuleMobile',
        api: API.CMS_GET_SUPPORT_MODULE_MOBILE,
      },
      {
        key: 'getGenericErrorMobile',
        api: API.CMS_GET_GENERIC_ERROR_MOBILE,
      },
      {
        key: 'getCartErrorsMobile',
        api: API.CMS_GET_CART_ERRORS_MOBILE,
      },
      {
        key: 'getRecurrentOrderErrorMobile',
        api: API.CMS_GET_RECURRENT_ORDER_ERROR_MOBILE,
      },
      {
        key: 'getInstantOrderErrorMobile',
        api: API.CMS_GET_INSTANT_ORDER_ERROR_MOBILE,
      },
      {
        key: 'getEditAddressMobile',
        api: API.CMS_GET_EDIT_ADDRESS_MOBILE,
      },
      {
        key: 'getEditAddress',
        api: API.CMS_GET_EDIT_ADDRESS,
      },
      {
        key: 'getMapMobile',
        api: API.CMS_GET_MAP_MOBILE,
      },
      {
        key: 'getStepperAddressConfigMobile',
        api: API.CMS_GET_EDIT_ADDRESS_STEPPER_MOBILE,
      },
      {
        key: 'getStepperAddressConfig',
        api: API.CMS_GET_EDIT_ADDRESS_STEPPER,
      },
      {
        key: 'getAllLeads',
        api: API.CMS_GET_ALL_LEADS,
      },
      {
        key: 'getDeviceCharacteristics',
        api: API.CMS_GET_DEVICE_CHARACTERISTICS,
      },
      {
        key: 'getBrightSkyConfigurations',
        api: API.CMS_GET_BRIGHT_SKY_CONF,
      },
      {
        key: 'getClickToCallConfigurations',
        api: API.CMS_GET_CLICK_TO_CALL_CONF,
      },
      {
        key: 'getClickToCallAppConfigurations',
        api: API.CMS_GET_CLICK_TO_CALL_APP_CONF,
      },
      {
        key: 'getDroppedCartConfigurations',
        api: API.CMS_GET_DROPPED_CARD,
      },
      {
        key: 'getDroppedCartHomepageConfigurations',
        api: API.CMS_GET_DROPPED_CART_HOMEPAGE,
      },
      {
        key: 'getGlobalConfigurations',
        api: API.CMS_GET_GLOBAL_CONFIG,
      },
      {
        key: 'getStickyBannerCms',
        api: API.CMS_GET_STICKY_BANNER,
      },
      {
        key: 'getfloatingbannerCms',
        api: API.CMS_GET_FLOATING_BANNER,
      },
      {
        key: 'getCtcAutoDesc',
        api: API.CMS_GET_CTC_AUTO_DESC,
      },
      {
        key: 'getFixedVouchersCms',
        api: API.CMS_GET_FIXED_VOUCHERS,
      },
      {
        key: 'getMobileVouchersCms',
        api: API.CMS_GET_MOBILE_VOUCHERS,
      },
      {
        key: 'getCustomerErrorFixed',
        api: API.CMS_GET_CUSTOMER_ERROR_FIXED,
      },
      {
        key: 'getCustomerErrorMobile',
        api: API.CMS_GET_CUSTOMER_ERROR_MOBILE,
      },
      {
        key: 'getVoucherInsertionManagentCms',
        api: API.CMS_GET_VOUCHER_INSERTION_MANAGMENT_CONF,
      },
      {
        key: 'getVoucherDiscount',
        api: API.CMS_GET_VOUCHER_DISCOUNT,
      },
      {
        key: 'getGlobalPromo',
        api: API.CMS_GET_GLOBAL_PROMO,
      },
      {
        key: 'getGlobalPromoIAdd',
        api: API.CMS_GET_GLOBAL_PROMO_IADD,
      },
      {
        key: 'getDigitalBundleOperators',
        api: API.CMS_GET_DIGITAL_BUNDLE_OPERATORS,
      },
      {
        key: 'getMultiFactorAuth',
        api: 'landing/multi-factor-auth',
      },
      {
        key: 'getPageNavigation',
        api: 'landing/homepage/page-navigation',
      },
      {
        key: 'getActivationLandingProduct',
        api: API.CMS_GET_ACTIVATION_LANDING_PRODUCT,
      },
      {
        key: 'getAllDeactivationModal',
        api: API.CMS_GET_ALL_DEACTIVATION_MODAL,
      },
      {
        key: 'getDetailsPage',
        api: API.CMS_GET_DETAILS_PAGE,
      },
      {
        key: 'getDetailsPageMobile',
        api: API.CMS_GET_DETAILS_PAGE_MOBILE,
      },
      {
        key: 'getCommonLabelsFixedLine',
        api: API.CMS_GET_STEPPER_COMMON_LABELS_FIXED,
      },
      {
        key: 'getCommonLabelsMobile',
        api: API.CMS_GET_STEPPER_COMMON_LABELS_MOBILE,
      },
    ],
  },
  [PAGES.GRANDIAZIENDE_HOME_PAGE]: {
    apis: [
      {
        key: 'getPageNavigation',
        api: API.CMS_GET_PAGE_GRANDIAZIENDE_NAVIGATION,
      },
      {
        key: 'getGenericError',
        api: API.CMS_GET_GENERIC_ERROR,
      },
      {
        key: 'getPages',
        api: API.CMS_GET_PAGES_GRANDIAZIENDE,
      },
      {
        key: 'getAllLeads',
        api: API.CMS_GET_ALL_LEADS,
      },
    ],
  },
  [PAGES.BUSINESS_HOME_PAGE]: {
    apis: [
      {
        key: 'getPageNavigation',
        api: API.CMS_GET_PAGE_BUSINESS_NAVIGATION,
      },
      {
        key: 'getGenericError',
        api: API.CMS_GET_GENERIC_ERROR,
      },
      {
        key: 'getPages',
        api: API.CMS_GET_PAGES_BUSINESS,
      },
      {
        key: 'getAllLeads',
        api: API.CMS_GET_ALL_LEADS,
      },
    ],
  },
  [PAGES.LANDING_CONSUMER]: {
    apis: [
      {
        key: 'getPageNavigation',
        api: API.CMS_GET_PAGE_NAVIGATION,
      },
      {
        key: 'getFatturazione2830',
        api: API.CMS_GET_FATTURAZIONE2830,
      },
      {
        key: 'getVideoRecognition',
        api: API.CMS_GET_VIDEO_RECOGNITION,
      },
      {
        key: 'getReshapingAll',
        api: API.CMS_GET_RESHAPING_ALL,
      },
      {
        key: 'getReshapingProductCards',
        api: API.CMS_GET_RESHAPING_PRODUCT_CARDS,
      },
      {
        key: 'getOnboardingCommonViews',
        api: API.CMS_GET_ONBOARDING_VIEWS_COMMON,
      },
      {
        key: 'getOnboardingCommonModules',
        api: API.CMS_GET_ONBOARDING_MODULES_COMMON,
      },
      {
        key: 'getOnboardingDashboardCommon',
        api: API.CMS_GET_ONBOARDING_DASHBOARD_COMMON,
      },
      {
        key: 'getOnboardingWizardTVBox',
        api: API.CMS_GET_ONBOARDING_WIZARD_TVBOX,
      },
      {
        key: 'getOnboardingWizardPowerStation',
        api: API.CMS_GET_ONBOARDING_WIZARD_POWERSTATION,
      },
      {
        key: 'getOnboardingWizardAlternativeModemFTTH',
        api: API.CMS_GET_ONBOARDING_WIZARD_ALTERNATIVE_MODEM_FTTH,
      },
      {
        key: 'getOnboardingWizardAlternativeModemADSL',
        api: API.CMS_GET_ONBOARDING_WIZARD_ALTERNATIVE_MODEM_ADSL,
      },
      {
        key: 'getOnboardingWizardAlternativeModemVDSL',
        api: API.CMS_GET_ONBOARDING_WIZARD_ALTERNATIVE_MODEM_VDSL,
      },
      {
        key: 'getOnboardingWizardPowerWiFi',
        api: API.CMS_GET_ONBOARDING_WIZARD_POWERWIFI,
      },
      {
        key: 'getOnboardingWizardWifiExtender',
        api: API.CMS_GET_ONBOARDING_WIZARD_WIFIEXTENDER,
      },
      {
        key: 'getOnboardingWizardInvoiceHelp',
        api: API.CMS_GET_ONBOARDING_WIZARD_INVOICE_HELP,
      },
      {
        key: 'getOnBoardingAllDevices',
        api: API.CMS_GET_ONBOARDING_DEVICES,
      },
      {
        key: 'getOnBoardingProductCards',
        api: API.CMS_GET_ONBOARDING_PRODUCT_CARDS,
      },
      {
        key: 'getOnBoardingOfferCards',
        api: API.CMS_GET_ONBOARDING_OFFER_CARDS,
      },
      {
        key: 'getOnBoardingYourOfferCards',
        api: API.CMS_GET_ONBOARDING_YOUR_OFFER_CARDS,
      },
      {
        key: 'getAllVouchers',
        api: API.CMS_GET_ONBOARDING_VOUCHERS,
      },
      {
        key: 'getTechAppointmentLandingPage',
        api: API.CMS_GET_TECH_APPOINTMENT,
      },
      {
        key: 'getAllStepperCardsForTech',
        api: API.CMS_GET_ONBOARDING_STEPPERCARDS_TECH,
      },
      {
        key: 'getOnboardingErrors',
        api: API.CMS_GET_ONBOARDING_ERRORS,
      },
      {
        key: 'getTechAppointmentFaq',
        api: API.CMS_GET_TECH_APPOINTMENT_FAQ,
      },
      {
        key: 'getAllLeads',
        api: API.CMS_GET_ALL_LEADS,
      },
      {
        key: 'getOneClick',
        api: API.CMS_GET_ONE_CLICK,
      },
      {
        key: 'getContractSummary',
        api: API.CMS_GET_CONTRACT_SUMMARY,
      },
      {
        key: 'getMultiFactorAuth',
        api: API.CMS_GET_MULTI_FACTOR_AUTH,
      },
    ],
  },
};

export const CONSUMER_CMS_HUB_APP: ICMSConfigPage = {
  apis: [
    {
      key: 'getPageNavigation',
      api: API.CMS_GET_PAGE_NAVIGATION,
    },
    {
      key: 'getHubApp',
      api: API.CMS_GET_HUB_APP,
    },
    {
      key: 'getHubAppActivation',
      api: API.CMS_GET_HUB_APP_ACTIVATION,
    },
  ],
};

export const CONSUMER_CMS_TPO_PAGE: ICMSConfigPage = {
  apis: [
    {
      key: 'getPageNavigation',
      api: API.CMS_GET_PAGE_NAVIGATION,
    },
    {
      key: 'getTpoPage',
      api: API.CMS_GET_TPO_PAGE,
    },
    {
      key: 'getTpoPageNavigation',
      api: API.CMS_GET_TPO_PAGE_NAVIGATION,
    },
    {
      key: 'getTpoEditorialConfig',
      api: API.CMS_GET_TPO_EDITORIAL_CONFIG,
    },
  ],
};

export const CONSUMER_CMS_ENRG_PAGE: ICMSConfigPage = {
  apis: [
    {
      key: 'getEnrgPage',
      api: API.CMS_GET_ENRG_PAGE,
    },
    {
      key: 'getPageNavigation',
      api: API.CMS_GET_PAGE_NAVIGATION,
    },
    {
      key: 'getEnrgPageNavigation',
      api: API.CMS_GET_ENRG_PAGE_NAVIGATION,
    },
  ],
};

export const CONSUMER_CMS_DEFAULT: ICMSConfigPage = {
  apis: [
    {
      key: 'getPageNavigation',
      api: API.CMS_GET_PAGE_NAVIGATION,
    },
    {
      key: 'getCustomPageNavigation',
      api: API.CMS_GET_CUSTOM_PAGE_NAVIGATION,
    },
    {
      key: 'getCoverageToolConfig',
      api: API.CMS_GET_COVERAGE_TOOL_CONFIG,
    },
    {
      key: 'getCmsTecnologies',
      api: API.CMS_GET_TECNOLOGIES,
    },
    {
      key: 'getCmsDevices',
      api: API.CMS_GET_DEVICES,
    },
    {
      key: 'getGenericError',
      api: API.CMS_GET_GENERIC_ERROR,
    },
    {
      key: 'getOrderError',
      api: API.CMS_GET_ORDER_ERROR,
    },
    {
      key: 'getSupportModule',
      api: API.CMS_GET_SUPPORT_MODULE,
    },
    {
      key: 'getSupportMobuleMobile',
      api: API.CMS_GET_SUPPORT_MODULE_MOBILE,
    },
    {
      key: 'getGenericErrorMobile',
      api: API.CMS_GET_GENERIC_ERROR_MOBILE,
    },
    {
      key: 'getRecurrentOrderErrorMobile',
      api: API.CMS_GET_RECURRENT_ORDER_ERROR_MOBILE,
    },
    {
      key: 'getInstantOrderErrorMobile',
      api: API.CMS_GET_INSTANT_ORDER_ERROR_MOBILE,
    },
    {
      key: 'getEditAddressMobile',
      api: API.CMS_GET_EDIT_ADDRESS_MOBILE,
    },
    {
      key: 'getEditAddress',
      api: API.CMS_GET_EDIT_ADDRESS,
    },
    {
      key: 'getAllLeads',
      api: API.CMS_GET_ALL_LEADS,
    },
    {
      key: 'getDeviceCharacteristics',
      api: API.CMS_GET_DEVICE_CHARACTERISTICS,
    },
    {
      key: 'getBrightSkyConfigurations',
      api: API.CMS_GET_BRIGHT_SKY_CONF,
    },
    {
      key: 'getDroppedCartConfigurations',
      api: API.CMS_GET_DROPPED_CARD,
    },
    {
      key: 'getGlobalConfigurations',
      api: API.CMS_GET_GLOBAL_CONFIG,
    },
    {
      key: 'getStickyBannerCms',
      api: API.CMS_GET_STICKY_BANNER,
    },
    {
      key: 'getfloatingbannerCms',
      api: API.CMS_GET_FLOATING_BANNER,
    },
    {
      key: 'getWinbackNumberCard',
      api: API.CMS_GET_WINBACK_DATA,
    },
    {
      key: 'getWinbackLegacyNumberCard',
      api: API.CMS_GET_WINBACK_LEGACY_DATA,
    },
    {
      key: 'getGlobalPromo',
      api: API.CMS_GET_GLOBAL_PROMO,
    },
    {
      key: 'getPersonalDataCard',
      api: API.CMS_GET_PERSONAL_DATA,
    },
    {
      key: 'getPersonalDataCardMobile',
      api: API.CMS_GET_PERSONAL_DATA_MOBILE,
    },
    {
      key: 'getOrderError',
      api: API.CMS_GET_ORDER_ERROR,
    },
    {
      key: 'getClickToCallConfigurations',
      api: API.CMS_GET_CLICK_TO_CALL_CONF,
    },
    {
      key: 'getClickToCallAppConfigurations',
      api: API.CMS_GET_CLICK_TO_CALL_APP_CONF,
    },
    {
      key: 'getDroppedCartHomepageConfigurations',
      api: API.CMS_GET_DROPPED_CART_HOMEPAGE,
    },
    {
      key: 'getCtcAutoDesc',
      api: API.CMS_GET_CTC_AUTO_DESC,
    },
    {
      key: 'getGlobalPromoIAdd',
      api: API.CMS_GET_GLOBAL_PROMO_IADD,
    },
    {
      key: 'getActivationLandingProduct',
      api: API.CMS_GET_ACTIVATION_LANDING_PRODUCT,
    },
    {
      key: 'getAllDeactivationModal',
      api: API.CMS_GET_ALL_DEACTIVATION_MODAL,
    },
    {
      key: 'getHubApp',
      api: API.CMS_GET_HUB_APP,
    },
    {
      key: 'getHubAppActivation',
      api: API.CMS_GET_HUB_APP_ACTIVATION,
    },
    {
      key: 'getTpoPage',
      api: API.CMS_GET_TPO_PAGE,
    },
    {
      key: 'getTpoPageNavigation',
      api: API.CMS_GET_TPO_PAGE_NAVIGATION,
    },
    {
      key: 'getTpoEditorialConfig',
      api: API.CMS_GET_TPO_EDITORIAL_CONFIG,
    },
    {
      key: 'getEnrgPage',
      api: API.CMS_GET_ENRG_PAGE,
    },
    {
      key: 'getEnrgPageNavigation',
      api: API.CMS_GET_ENRG_PAGE_NAVIGATION,
    },
    {
      key: 'getWinbackConfiguration',
      api: API.CMS_GET_WINBACK_CONFIG,
    },
    {
      key: 'getBestOfferVfFw',
      api: API.CMS_GET_BEST_OFFER_VF_FW,
    },
  ],
};

export const CONSUMER_CMS_SHOPPING_CART: ICMSConfigPage = {
  apis: [
    ...CONSUMER_CMS_DEFAULT.apis,
    {
      key: 'getMobileLinePayments',
      api: API.CMS_GET_PAYMENTS_MOBILE,
    },
    {
      key: 'getDeliveryInformationMobile',
      api: API.CMS_GET_DELIVERY_INFORMATION_MOBILE,
    },
    {
      key: 'getPortabilityCard',
      api: API.CMS_GET_PORTABILITY,
    },
    {
      key: 'getPortabilityCardMobile',
      api: API.CMS_GET_PORTABILITY_MOBILE,
    },
    {
      key: 'getFamilyPlusPayment',
      api: API.CMS_GET_FAMILY_PLUS_PAYMENT,
    },
    {
      key: 'getBillingInformation',
      api: API.CMS_GET_BILLING_INFORMATION,
    },
    {
      key: 'getSupportModule',
      api: API.CMS_GET_SUPPORT_MODULE,
    },
    {
      key: 'getTermsAndConditionsCard',
      api: API.CMS_GET_TERMS_AND_CONDITIONS,
    },
    {
      key: 'getTermsAndConditionsCardMobile',
      api: API.CMS_GET_TERMS_AND_CONDITIONS_MOBILE,
    },
    {
      key: 'getDeliveryInformation',
      api: API.CMS_GET_DELIVERY_INFORMATION,
    },
    {
      key: 'getPayments',
      api: API.CMS_GET_PAYMENTS,
    },
    {
      key: 'getMap',
      api: API.CMS_GET_MAP,
    },
    {
      key: 'getOrderError',
      api: API.CMS_GET_ORDER_ERROR,
    },
    {
      key: 'getSupportMobuleMobile',
      api: API.CMS_GET_SUPPORT_MODULE_MOBILE,
    },
    {
      key: 'getMapMobile',
      api: API.CMS_GET_MAP_MOBILE,
    },
    {
      key: 'getFixedVouchersCms',
      api: API.CMS_GET_FIXED_VOUCHERS,
    },
    {
      key: 'getMobileVouchersCms',
      api: API.CMS_GET_MOBILE_VOUCHERS,
    },
    {
      key: 'getCustomerErrorFixed',
      api: API.CMS_GET_CUSTOMER_ERROR_FIXED,
    },
    {
      key: 'getCustomerErrorMobile',
      api: API.CMS_GET_CUSTOMER_ERROR_MOBILE,
    },
    {
      key: 'getCommonLabelsFixedLine',
      api: API.CMS_GET_STEPPER_COMMON_LABELS_FIXED,
    },
    {
      key: 'getCommonLabelsMobile',
      api: API.CMS_GET_STEPPER_COMMON_LABELS_MOBILE,
    },
    {
      key: 'getCmsAddons',
      api: API.CMS_GET_ADDONS,
    },
    {
      key: 'getCmsAddonsMobile',
      api: API.CMS_GET_ADDONS_MOBILE,
    },
    {
      key: 'getAddonsCommon',
      api: API.CMS_GET_ADDONS_COMMON,
    },
    {
      key: 'getAddonsCommonMobile',
      api: API.CMS_GET_ADDONS_COMMON_MOBILE,
    },
    {
      key: 'getCartErrorsMobile',
      api: API.CMS_GET_CART_ERRORS_MOBILE,
    },
    {
      key: 'getStepperAddressConfigMobile',
      api: API.CMS_GET_EDIT_ADDRESS_STEPPER_MOBILE,
    },
    {
      key: 'getStepperAddressConfig',
      api: API.CMS_GET_EDIT_ADDRESS_STEPPER,
    },
    {
      key: 'getVoucherInsertionManagentCms',
      api: API.CMS_GET_VOUCHER_INSERTION_MANAGMENT_CONF,
    },
    {
      key: 'getVoucherDiscount',
      api: API.CMS_GET_VOUCHER_DISCOUNT,
    },
    {
      key: 'getDigitalBundleOperators',
      api: API.CMS_GET_DIGITAL_BUNDLE_OPERATORS,
    },
    {
      key: 'getDetailsPage',
      api: API.CMS_GET_DETAILS_PAGE,
    },
    {
      key: 'getDetailsPageMobile',
      api: API.CMS_GET_DETAILS_PAGE_MOBILE,
    },
    {
      key: 'getContractSummary',
      api: API.CMS_GET_CONTRACT_SUMMARY,
    },
  ],
};

export const CONSUMER_CMS_THANKYOU: ICMSConfigPage = {
  apis: [
    ...CONSUMER_CMS_DEFAULT.apis,
    {
      key: 'getThankYouPage',
      api: API.CMS_GET_THANK_YOU_PAGE,
    },
    {
      key: 'getThankYouMobile',
      api: API.CMS_GET_THANK_YOU_PAGE_MOBILE,
    },
  ],
};

export const CONSUMER_CMS_ABROAD: ICMSConfigPage = {
  apis: [
    ...CONSUMER_CMS_DEFAULT.apis,
    {
      key: 'getNations',
      api: API.CMS_GET_NATIONS_CBU,
    },
    {
      key: 'getConsumerOffersFilter',
      api: API.CMS_CTO_GET_OFFERS_FILTER,
    },
    {
      key: 'getBusinessPlansOperator',
      api: API.CMS_CTO_GET_PLANS_OPERATOR_CBU,
    },
    {
      key: 'getConsumerWorldOffers',
      api: API.CMS_CTO_GET_WORLD_OFFERS,
    },
    {
      key: 'getPageNavigation',
      api: API.CMS_GET_PAGE_NAVIGATION,
    },
    {
      key: 'getGenericError',
      api: API.CMS_GET_GENERIC_ERROR,
    },
    {
      key: 'getTariffeEsteroConsumerHome',
      api: API.CMS_ABROAD_GET_HOME,
    },
    {
      key: 'getTariffeEsteroConsumerHomeV2',
      api: API.CMS_ABROAD_GET_HOME_V2,
    },
    {
      key: 'getTariffeEsteroConsumerRoamingAereo',
      api: API.CMS_ABROAD_GET_ROAMING_AEREO,
    },
    {
      key: 'getTariffeEsteroConsumerRoamingAereoV2',
      api: API.CMS_ABROAD_GET_ROAMING_AEREO_V2,
    },
    {
      key: 'getTariffeEsteroConsumerPaeseUe',
      api: API.CMS_ABROAD_GET_PAESE_UE,
    },
    {
      key: 'getTariffeEsteroConsumerPaeseUeV2',
      api: API.CMS_ABROAD_GET_PAESE_UE_V2,
    },
    {
      key: 'getTariffeEsteroConsumerDettaglioPaeseUe',
      api: API.CMS_ABROAD_GET_DETTAGLIO_PAESE_UE,
    },
    {
      key: 'getTariffeEsteroConsumerDettaglioPaeseUeV2',
      api: API.CMS_ABROAD_GET_DETTAGLIO_PAESE_UE_V2,
    },
    {
      key: 'getTariffeEsteroConsumerDettaglioPaeseMondo',
      api: API.CMS_ABROAD_GET_DETTAGLIO_PAESE_MONDO,
    },
    {
      key: 'getTariffeEsteroConsumerDettaglioPaeseMondoV2',
      api: API.CMS_ABROAD_GET_DETTAGLIO_PAESE_MONDO_V2,
    },
    {
      key: 'getTariffeEsteroConsumerDettaglioPaeseEnrichedV2',
      api: API.CMS_ABROAD_GET_DETTAGLIO_PAESE_ENRICHED_V2,
    },
    {
      key: 'getTariffeEsteroConsumerDettaglioTestEditingsV2',
      api: API.CMS_ABROAD_GET_DETTAGLIO_TEST_EDITINGS_V2,
    },
    {
      key: 'getTariffeEsteroEditorialConfigs',
      api: API.CMS_ABROAD_GET_EDITORIAL_CONFIG,
    },
    {
      key: 'getBusinessFixedLinePlaceholders',
      api: API.CMS_BFL_GET_COMMON_PLACEHOLDER,
    },
  ],
};

export const useCmsConfig = (page: ICMSConfigPage, apiKey?: string): IAllDataCMS => {
  const { apis } = page;
  const allData = {};
  apis?.forEach((api) => {
    const client = useQueryClient();
    const data = client.getQueryData(api.key, { exact: false });
    allData[api.api] = data;
  });
  return apiKey ? allData[apiKey] : allData;
};
